
<template>
    <component :is="'div'">

        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="doctorData === undefined"
        >
            <h4 class="alert-heading">Error al obtener los datos del doctor</h4>
            <div class="alert-body">
                No se encontró ningún doctor con este id. Verificar en la
                <b-link
                    class="alert-link"
                    :to="{ name: 'doctors'}"
                >
                    Lista de doctores
                </b-link>
                para buscar otro doctor.
            </div>
        </b-alert>

        <b-tabs
            v-if="doctorData"
            pills
        >
      
            <!-- Tab: Information -->
            <b-tab>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Información</span>
                </template>
                <doctor-edit-tab-information
                    class="mt-2 pt-75"
                    :doctor-data="doctorData"
                />
            </b-tab>
      
        </b-tabs>
    </component>
</template>

<script>

    import DoctorEditTabInformation from './DoctorEditTabInformation.vue';
    import {onUnmounted, ref} from '@vue/composition-api';
    import doctorStoreModule from '../doctorStoreModule';
    import router from '@/router';
    import store from '@/store';

    export default {
        components: {
            DoctorEditTabInformation
        },
        setup() {
            const doctorData = ref(null);

            const DOCTOR_APP_STORE_MODULE_NAME = 'app-doctor';

            // Register module
            if (!store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.registerModule(DOCTOR_APP_STORE_MODULE_NAME, doctorStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.unregisterModule(DOCTOR_APP_STORE_MODULE_NAME)
            });

            store.dispatch('app-doctor/fetchDoctor', { id: router.currentRoute.params.id })
                .then(response => {
                    if (response.data === 'error') {
                        doctorData.value = undefined
                    } else {
                        doctorData.value = response.data;
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        doctorData.value = undefined
                    }
                });

            return {
                doctorData
            }
        }
    }

</script>