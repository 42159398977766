<template>
    <validation-observer ref="refForm">

        <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
        >

            <b-row>
                <b-col md="12">
                    <b-card footer-tag="footer">

                        <b-row>

                            <b-col cols="12">
                                <b-alert variant="info" :show="true">
                                    <div class="alert-body">
                                        <ul class="mb-0">
                                            <li>
                                                Comprime y optimiza tus imágenes en 
                                                <b-link
                                                    class="alert-link"
                                                    href="https://tinypng.com/"
                                                    target="_blank"
                                                >
                                                    Tinify
                                                </b-link>
                                            </li>
                                            <li>
                                                Convierte tus imágenes a .webp en 
                                                <b-link
                                                    class="alert-link"
                                                    href="https://pixelied.com/convert/jpg-converter"
                                                    target="_blank"
                                                >
                                                    Pixelied
                                                </b-link>
                                            </li>
                                        </ul>
                                    </div>
                                </b-alert>
                            </b-col>

                            <!-- MAIN IMAGE -->
                            <b-col md="12">

                                <!-- PREVIEW MAIN IMG -->
                                <div id="main-preview" v-if="formData.mainUrl">
                                    <b-img
                                        fluid
                                        :src="formData.mainUrl"
                                        thumbnail
                                        width="200"
                                        alt="image"
                                    />
                                </div>

                                <!-- IMG -->
                                <b-form-group
                                    label-for="mainImage"
                                >
                                    <template #label>
                                        <strong>IMAGEN PRINCIPAL (FORMATO: webp)</strong>
                                    </template>
                                    <b-form-file
                                        id="mainImage"
                                        v-model="formData.mainImage"
                                        @change="onMainFileChange"
                                        accept=".webp"
                                        ref="mainImage"
                                        placeholder="Elija otra imagen principal ..."
                                        no-drop
                                    />
                                </b-form-group>
                                <b-button variant="outline-primary" class="mb-2" @click="restoreMainImage" v-if="formData.mainImage">Restaurar imagen principal</b-button>
                            </b-col>

                            <!-- FULLNAME -->
                            <b-col cols="12" md="6">
                                <b-form-group
                                    label-for="fullname"
                                >
                                    <template #label>
                                        <strong>NOMBRE COMPLETO</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="nombre completo"
                                    >
                                        <b-form-input
                                            id="fullname"
                                            v-model="formData.fullname"
                                            placeholder="Ingrese el nombre completo ..."
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- POSITION -->
                            <b-col cols="12" md="6">
                                <b-form-group
                                    label-for="position"
                                >
                                    <template #label>
                                        <strong>POSICIÓN</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="posición"
                                    >
                                        <b-form-input
                                            id="position"
                                            v-model="formData.position"
                                            placeholder="Ingrese la posición ..."
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- CPM -->
                            <b-col cols="12" md="6">
                                <b-form-group
                                    label-for="cpm"
                                >
                                    <template #label>
                                        <strong>CPM</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="CPM"
                                    >
                                        <b-form-input
                                            id="cpm"
                                            v-model="formData.cpm"
                                            placeholder="Ingrese el CPM ..."
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- MEDICAL DEGREE -->
                            <b-col cols="12" md="6">
                                <b-form-group
                                    label-for="medicalDegree"
                                >
                                    <template #label>
                                        <strong>TÍTULO MÉDICO</strong>
                                    </template>
                                    <b-form-input
                                        id="medicalDegree"
                                        v-model="formData.medicalDegree"
                                        placeholder="Ingrese el título médico ..."
                                        trim
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- LINKEDIN -->
                            <b-col cols="12" md="6">
                                <b-form-group
                                    label-for="linkedin"
                                >
                                    <template #label>
                                        <strong>LINKEDIN URL</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="url"
                                        name="linkedin url"
                                    >
                                        <b-form-input
                                            id="linkedin"
                                            v-model="formData.linkedin"
                                            placeholder="Ingrese el enlace de linkedin ..."
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- SOCIETY -->
                            <b-col cols="12" md="6">
                                <b-form-group
                                    label-for="society"
                                >
                                    <template #label>
                                        <strong>SOCIEDAD</strong>
                                    </template>
                                    <b-form-input
                                        id="society"
                                        v-model="formData.society"
                                        placeholder="Ingrese la sociedad a la que pertenece ..."
                                        trim
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- CV FILE -->
                            <b-col md="12">

                                <b-form-group
                                    label-for="cvFile"
                                >
                                    <template #label>
                                        <strong>CV (FORMATO: PDF)</strong>
                                    </template>
                                    <b-form-file
                                        id="cvFile"
                                        v-model="formData.cvFile"
                                        @change="onCVFileChange"
                                        accept=".pdf"
                                        ref="cvFile"
                                        placeholder="Elija una archivo ..."
                                        no-drop
                                    />
                                </b-form-group>
                                <b-button variant="outline-primary" class="mb-2 mr-2" @click="removeCVFile" v-if="formData.cvFile">Eliminar CV</b-button>
                                <b-button variant="outline-success" class="mb-2" @click="openLinkAnotherTab(formData.cvFileUrl)" v-if="formData.cvFileUrl">Descargar CV</b-button>
                            
                            </b-col>

                            <!-- DESCRIPTION -->
                            <b-col cols="12">
                                <b-form-group
                                    label-for="description"
                                >
                                    <template #label>
                                        <strong>DESCRIPCIÓN</strong>
                                    </template>
                                    <b-form-textarea
                                        id="description"
                                        v-model="formData.description"
                                        class="char-textarea"
                                        placeholder="Descripción ..."
                                        rows="5"
                                    />
                                    <small class="textarea-counter-value float-right">
                                        <strong class="char-count">Caracteres: {{ formData.description ? formData.description.length : 0 }}</strong>
                                    </small>
                                </b-form-group>
                            </b-col>

                        </b-row>

                        <template #footer>

                            <!-- BUTTON SUBMIT -->
                            <b-button-loading 
                                text="ACTUALIZAR" 
                                type="submit"
                                :processing="processing">
                            </b-button-loading>

                        </template>

                    </b-card>
                </b-col>
            </b-row>

        </b-form>

    </validation-observer>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { useToast } from 'vue-toastification/composition';
    import doctorStoreModule from '../doctorStoreModule';
    import Ripple from 'vue-ripple-directive';
    import { required } from '@validations';
    import store from '@/store';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading,

            required
        },
        directives: {
            Ripple
        },
        props: {
            doctorData: {
                type: Object,
                required: true
            }
        },
        setup(props) {

            // USE TOAST
            const toast = useToast();

            // REFS
            const doctorData = ref(props.doctorData);
            
            const processing = ref(false);
            const refForm = ref(null);
            
            const mainImage = ref(null);
            const cvFile = ref(null);

            const formData = ref({
                id: null,
                mainUrl: null,
                mainImage: null,
                cvFileUrl: null,
                cvFile: null,
                fullname: null,
                position: null,
                cpm: null,
                medicalDegree: null,
                society: null,
                linkedin: null,
                description: null
            });

            const DOCTOR_APP_STORE_MODULE_NAME = 'app-doctor';

			// REGISTER MODULE
			if (!store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.registerModule(DOCTOR_APP_STORE_MODULE_NAME, doctorStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.unregisterModule(DOCTOR_APP_STORE_MODULE_NAME);
			});

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( () => {
                setData();
            });

            const setData = () => {
                
                formData.value = {
                    ...doctorData.value,
                    medicalDegree: doctorData.value.medical_degree,
                    linkedin: doctorData.value.linkedin_url,
                    cvFile: null,
                    mainImage: null
                }

            };

            const getDoctorData = async () => {

                store.dispatch('app-doctor/fetchDoctor', { id: formData.value.id })
                    .then(response => {
                        doctorData.value = response.data;
                        setData();
                    });

            };

            const onMainFileChange = (e) => {
                const file = e.target.files[0];

                if (file) {
                    formData.value.mainImage = file;
                    formData.value.mainUrl = URL.createObjectURL(file);
                } else {
                    formData.value.mainImage = null;
                    formData.value.mainUrl = null;
                }
            };

            const onCVFileChange = (e) => {
                const file = e.target.files[0];

                if (file) {
                    formData.value.cvFile = file;
                    formData.value.cvFileUrl = URL.createObjectURL(file);
                } else {
                    formData.value.cvFile = null;
                    formData.value.cvFileUrl = null;
                }
            };

            const restoreMainImage = () => {
                formData.value.mainUrl = doctorData.value.mainUrl;
                formData.value.mainImage = null;

                mainImage.value.reset();
            };

            const removeCVFile = () => {
                formData.value.cvFile = null;
                cvFile.value.reset();
            };

            const openLinkAnotherTab = (url) => {
                window.open(url, '_blank')
            };

            const onSubmit = async () => {

                const isValid = await refForm.value.validate();

                if (!isValid)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Complete los campos requeridos.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });
                    
                    return;
                }

                processing.value = true;

                const {

                    id,

                    mainImage,
                    cvFile,
                    fullname,
                    position,
                    cpm,
                    medicalDegree,
                    society,
                    linkedin,
                    description

                } = formData.value;

                const payload = {
                    mainImage,
                    cvFile,
                    fullname,
                    position,
                    cpm,
                    medicalDegree,
                    society,
                    linkedin,
                    description
                }

                store.dispatch('app-doctor/updateDoctor', { id, payload })
                    .then( response => {

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                        formData.value.cvFile = null;
                        formData.value.mainImage = null;

                        getDoctorData();

                    })
                    .catch( (err) => {
                        const message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información general del/la doctor(a).';
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    })
                    .finally( () =>  processing.value = false);

            };

            return {
                // DATA
                processing,
                formData,

                // REFS
                refForm,
                cvFile,
                mainImage,

                // METHODS
                openLinkAnotherTab,
                onMainFileChange,
                restoreMainImage,
                onCVFileChange,
                removeCVFile,
                onSubmit
            }
        }
    }

</script>

<style lang="scss" scoped>

    #main-preview {
        text-align: center;
        margin-bottom: 1rem;
    }


</style>